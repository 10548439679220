.choice-result {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: none;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(4, 4, 23, 0.66);
}

.choice-result .choice-result-title {
  font-size: 150px;
  text-transform: uppercase;
  color: #1ef733;
  display: flex;
  align-items: flex-end;
}

.choice-result .choice-result-description {
  font-size: 22px;
  color: #fff;
  margin: 0px 200px 40px 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choice-result .choice-result-description .choice-result-right-description {
  background: #010a44;
  color: #fff;
  padding: 1em 2em;
  margin-top: 1em;
  font-size: 18px;
}

.choice-result .choice-result-description span {
  font-family: Roboto;
}

.choice-result .choice-result-button {
  display: flex;
  align-items: baseline;
}

.choice-result .choice-result-button .btn-choice-result {
  border: 0;
  color: #fff;
  padding: 35px 150px 0 150px;
  font-size: 50px;
  background: rgba(4, 4, 23, 0.66);
  text-transform: uppercase;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.choice-result .choice-result-button .btn-choice-result:hover {
  background: #ff5c35;
}

.show-animation {
  display: flex;
  z-index: 1000;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.correct-choice {
  background: url('../../assets/true.png') no-repeat center/cover;
  width: 105px;
  height: 105px;
}

.incorrect-choice {
  background: url('../../assets/false.png') no-repeat center/cover;
  width: 105px;
  height: 105px;
}

.choice-result-title-container-en {
  margin-bottom: 30px;
}

.choice-result-title-en {
  padding: 0 !important;
  margin-top: 30px !important;
}

/*TODO optimize this 2 classes*/
.correct-title {
  color: #1ef733;
  margin-top: 100px;
  font-size: 120px;
  line-height: 50px;
}

.incorrect-title {
  color: #f61e1e;
  margin-top: 100px;
  font-size: 120px;
  line-height: 50px;
}

@media only screen and (min-width: 480px) and (max-width: 900px) and (orientation: landscape) {
  .choice-result .choice-result-title {
    font-size: 45px;
  }

  .correct-choice,
  .incorrect-choice {
    width: 45px;
    height: 45px;
    margin-left: 20px !important;
  }

  .choice-result .choice-result-description {
    font-size: 16px;
  }

  .choice-result .choice-result-button .btn-choice-result {
    font-size: 16px;
    padding: 1em 3em;
  }

  .choice-result .choice-result-description .choice-result-right-description {
    font-size: 16px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .choice-result .choice-result-title {
    font-size: 45px;
  }

  .correct-choice,
  .incorrect-choice {
    width: 45px;
    height: 45px;
    margin-left: 20px !important;
  }

  .correct-title,
  .incorrect-title {
    margin-top: 40px;
    font-size: 45px;
    line-height: 45px;
  }

  .choice-result-title-en {
    margin-top: 5px !important;
  }

  .choice-result .choice-result-description {
    font-size: 10px;
    margin: 0px 100px 10px 100px;
  }

  .choice-result .choice-result-button .btn-choice-result {
    font-size: 20px;
    padding: 20px 70px 0 70px;
  }

  .choice-result .choice-result-description .choice-result-right-description {
    font-size: 10px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1400px) {
  .correct-choice,
  .incorrect-choice {
    width: 70px;
    height: 70px;
    margin-left: 20px !important;
  }

  .correct-title,
  .incorrect-title {
    margin-top: 61px;
    font-size: 70px;
    line-height: 70px;
  }

  .choice-result-title-en {
    margin-top: 5px !important;
  }

  .choice-result .choice-result-description {
    font-size: 14px;
    margin: 0px 100px 10px 100px;
  }

  .choice-result .choice-result-description .choice-result-right-description {
    font-size: 12px;
  }

  .choice-result .choice-result-button .btn-choice-result {
    font-size: 24px;
    padding: 20px 70px 0 70px;
  }
}

.localization {
    position: absolute;
    right: 4.5em;
    top: 1em;
    z-index: 1002;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.localization-language {
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    opacity: 0.5;
}

.localization-language-active {
    opacity: 1;
}
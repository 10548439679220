.make-choose {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.make-choose .item {
  flex: 1 1 0px;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: inherit;
  background: rgba(4, 4, 23, 0.4);
  margin: 0 3.5em;
  padding: 0 35px;
  color: #ffffff;
}

.make-choose .item:hover {
  background: #ff5c35;
}

.make-choose .item span {
  font-size: 90px;
  text-align: center;
  line-height: 90px;
  text-transform: uppercase;
  white-space: break-spaces;
}

.make-choose .item .choice-title {
  font-size: 130px;
  text-align: center;
  line-break: auto;
  font-family: Pompiere;
}

.make-choose .disabled {
  opacity: 0.4;
  pointer-events: none;
}

.make-choose .disabled:hover {
  background: rgba(4, 4, 23, 0.4);
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .make-choose .item span {
    font-size: 50px;
    line-height: 50px;
  }

  .make-choose .item .choice-title {
    font-size: 55px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1400px) {
  .make-choose .item span {
    font-size: 60px;
    line-height: 60px;
  }
}

.team {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%);
  z-index: 1001;
  backdrop-filter: blur(10px);
}

.team-container {
  margin: 2em 100px;
}

.team-title {
  color: #fff;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.team-member {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  /*line-height: 18px;*/
  display: inline-block;
}

.show-animation {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@media only screen and (min-width: 300px) and (max-width: 850px) {
  .team-title,
  .team-member {
    font-size: 6px;
    line-height: 9px;
  }

  .team-member {
    margin: 1px;
  }
}

@media only screen and (min-width: 850px) and (max-width: 900px) {
  .team-title,
  .team-member {
    font-size: 8px;
    line-height: 12px;
  }

  .team-member {
    margin: 2px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1550px) {
  .team-title,
  .team-member {
    font-size: 14px;
    line-height: 18px;
  }

  .team-member {
    margin: 3px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1250px) {
  .team-title,
  .team-member {
    font-size: 10px;
    line-height: 12px;
  }

  .team-member {
    margin: 3px;
  }
}

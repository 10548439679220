.g2l-logo {
    display: block;
    background: url('../../../assets/global_to_local.svg') no-repeat center/cover;
    width: 105px;
    height: 38px;
    margin: 20px auto;
}

.side-bar {
    -webkit-box-shadow: 4px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 0px 8px 0px rgba(34, 60, 80, 0.2);
}

.action-icon {
    font-size: 20px;
    cursor: pointer;
}

.dashboard * {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
}

.dashboard {
    height: 100vh;
    overflow: scroll !important;
}

.spinning-loader {
    display: flex !important;
    margin: 0 auto !important;
    font-size: 45px;
    width: 100px !important;
    height: 100px !important;
}
.information-cards-container {
  /*position: absolute;*/
  /*right: 2.5em;*/
  /*top: 2.5em;*/
  padding: 1px;
  width: 256px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.07);
}

.information-cards-list {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.information-cards-list-item {
  color: #000;
  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 0.2em 0;
  cursor: pointer;
}

.information-card-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%);
  z-index: 1001;
  backdrop-filter: blur(10px);
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.information-card-button {
  background: url('../../../assets/close.svg') no-repeat center/cover;
  cursor: pointer;
  width: 42px;
  height: 42px;
  position: absolute;
  right: 0;
  top: -45px;
}

.information-card {
  width: 65%;
  margin: 0 5em;
  padding: 3em;
  border-radius: 10px;
  background: rgba(204, 218, 255, 1);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.58);
  position: relative;
}

.information-card-title {
  color: #0a0909;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.58px;
  text-transform: uppercase;
}

.information-card-description {
  color: #0a0909;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.4px;
}

.information-card-description-bold {
  color: #0a0909;
  text-transform: uppercase;
  font-weight: 700;
}

.information-card-outro {
  border-radius: 10px;
  background: #0f2764;
  position: absolute;
  margin-right: 3.5em;
  padding: 1em;
}

.information-card-outro-text {
  color: #fff;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .information-cards-container {
    right: 0.5em;
    bottom: 4.5em;
    width: 180px;
  }

  .information-cards-list-item {
    font-size: 8px;
  }

  .information-card {
    margin: 0 3em;
    padding: 1em 2em;
    width: 60vw;
  }

  .information-card-outro {
    margin-right: 3em;
    padding: 0.5em;
  }

  .information-card-title {
    font-size: 12px;
  }

  .information-card-outro-text {
    font-size: 8px;
  }

  .information-card-description {
    font-size: 10px;
    line-height: 12px;
  }
}

/*@media only screen and (min-width: 900px) and (max-width: 1100px) {*/
/*    .information-card {*/
/*        margin: 0 3em;*/
/*        padding: 2em;*/
/*        width: 60vw;*/
/*    }*/

/*    .information-card-title {*/
/*        font-size: 24px;*/
/*    }*/

/*    .information-card-description {*/
/*        font-size: 16px;*/
/*        line-height: 18px;*/
/*    }*/

/*    .information-card-outro-text {*/
/*        font-size: 14px;*/
/*    }*/
/*}*/

@media only screen and (min-width: 1100px) and (max-width: 1500px) {
  .information-card {
    margin: 0 3em;
    padding: 2em;
    width: 60vw;
  }

  .information-card-title {
    font-size: 24px;
  }

  .information-card-description {
    font-size: 16px;
    line-height: 18px;
  }

  .information-card-outro-text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .information-card {
    padding: 2em;
  }

  .information-card-title {
    font-size: 24px;
  }

  .information-card-description {
    font-size: 12px;
    line-height: 15px;
  }

  .information-card-outro-text {
    font-size: 12px;
  }
}

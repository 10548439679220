.rotate-device {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: rgba(4, 4, 23, 0.66);
  z-index: 1003;
  text-align: center;
  backdrop-filter: blur(10px);
}

.rotate-device span {
  color: #ffffff;
  font-size: 24px;
  line-break: auto;
  padding: 0px 20px;
}

.rotate-device-title span {
  padding: 0;
  font-size: 80px;
  text-transform: uppercase;
}

.rotate-device-title .orange {
  color: #ff5c35;
}

.rotate-device-icon {
  background: url('../../assets/rotate_page.png') no-repeat center/cover;
  width: 170px;
  height: 170px;
}

.rotate-device-text span {
  font-size: 50px;
  padding: 0;
  line-height: 38px;
}

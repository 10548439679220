.send-story {
  /*position: absolute;*/
  /*right: 2.5em;*/
  /*top: 2.5em;*/
  padding: 1px;
  width: 450px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.07);
}

.story-title {
  color: #000;
  font-size: 10px;
  letter-spacing: 0.2px;
  margin: 10px 25px;
}

.send-story-form {
  display: flex;
  justify-content: start;
  align-items: flex-end;
}

.send-story-textarea {
  width: 377px;
  height: 91px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #e8e8e8;
  border: 0;
  resize: none;
  outline: 0;
  margin: 0 10px 0 25px;
}

.send-story-button {
  background: url('../../../assets/location.svg') no-repeat center/cover;
  width: 28px;
  height: 28px;
  border: 0;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .send-story {
    right: 0.5em;
    bottom: 4.5em;
    width: 350px;
  }

  .send-story-textarea {
    width: 277px;
    height: 45px;
  }

  .send-story-form {
    font-size: 12px;
  }
}

@media only screen and (max-height: 450px) {
  .send-story-textarea {
    height: 45px;
  }
}

@media only screen and (max-height: 400px) {
  .send-story-textarea {
    height: 35px;
  }
}

html,
body {
  height: 100%;
  /*overflow: hidden;*/
}

video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.video-player {
  width: 100%;
}

.video-player .video-js {
  height: 100vh !important;
}

.video-player .video-js video {
  outline: none;
}

.video-js .vjs-custom-button {
  background-image: url('./assets/play_button.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  /*background: red;*/
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 100;
}

@media only screen and (min-width: 900px) and (max-width: 1380px) {
  .video-js .vjs-custom-button {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .video-js .vjs-custom-button {
    width: 60px;
    height: 60px;
  }
}

.intro {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  background: linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%);
}

.intro-title {
  font-size: 220px;
  align-items: flex-end;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  /*line-height: 340px;*/
  width: 100%;
  justify-content: center;
  position: relative;
  /*top: 0.5em;*/
  height: 33%;
}

.intro-title-container {
  position: absolute;
  bottom: -0.8em;
  display: flex;
  align-items: baseline;
}

.intro-title-container-en {
  bottom: -0.5em !important;
}

.intro-title span {
  color: inherit;
}

.intro-title .orange {
  color: #ff5c35;
}

.sub-title {
  font-size: 100px;
  /*margin-top: 1.2em;*/
  letter-spacing: 1.75px;
}

.intro-episode {
  width: 100%;
  height: 33%;
  text-align: center;
  justify-content: center;
  /*position: absolute;*/
  /*bottom: 13em;*/
}

.intro-episode span {
  font-size: 64px;
  letter-spacing: 17px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.hide {
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  /*animation-delay: 1s;*/
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  pointer-events: none;
  display: none;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .intro-title {
    font-size: 100px;
  }

  .intro-title-container-en {
    bottom: -0.5em !important;
  }

  .intro-episode {
    /*bottom: 6em;*/
  }

  .intro-episode span {
    font-size: 30px;
    -webkit-text-stroke: 2px #fff;
    text-stroke: 2px #fff;
  }

  .sub-title {
    font-size: 40px;
  }

  /*.intro-episode span {*/
  /*    font-size: 35px;*/
  /*}*/
}

@media only screen and (min-width: 900px) and (max-width: 1500px) {
  .intro-title {
    font-size: 130px;
    /*top: 1.2em;*/
  }

  .intro-title-container-en {
    bottom: -0.5em !important;
  }

  .intro-episode span {
    font-size: 45px;
  }

  .intro-episode {
    /*bottom: 7em;*/
  }

  .sub-title {
    font-size: 60px;
  }
}

/*@media only screen and (max-height: 800px) {*/
/*    .intro-title {*/
/*        top: 1.2em;*/
/*    }*/
/*}*/

/*@media only screen and (min-width: 1366px) and (max-width: 1920px) {*/
/*    .intro-title {*/
/*        font-size: 180px;*/
/*        top: 1em;*/
/*    }*/
/*    .intro-episode {*/
/*        bottom: 8em;*/
/*    }*/
/*}*/

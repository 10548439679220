.menu-container {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1002;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.menu-btn {
  background: url('../../assets/menu.svg') no-repeat center/cover;
  width: 36px;
  height: 36px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.menu-item {
  width: 36px;
  height: 36px;
  margin: 0.5em 0;
  position: relative;
}

.menu-item:hover p {
  display: block;
}

.info-btn {
  background: url('../../assets/info.svg') no-repeat center/cover;
}

.info-btn-active {
  background: url('../../assets/info_active.svg') no-repeat center/cover;
}

.team-btn {
  background: url('../../assets/team.svg') no-repeat center/cover;
}

.team-btn-active {
  background: url('../../assets/team_active.svg') no-repeat center/cover;
}

.information-cards-btn {
  background: url('../../assets/information_cards.svg') no-repeat center/cover;
}

.information-cards-btn-active {
  background: url('../../assets/information_cards_active.svg') no-repeat center/cover;
}

.share-story-btn {
  background: url('../../assets/share_story.svg') no-repeat center/cover;
}

.share-story-btn-active {
  background: url('../../assets/share_story_active.svg') no-repeat center/cover;
}

.menu-item-label {
  display: none;
  margin: 0;
  white-space: nowrap; /* Prevent text from wrapping */
  transform: translateX(-100%);
  color: #fff;
  position: absolute;
  background: #9ca0b8;
  padding: 5px 20px 5px 10px;
  /*top: 8px;*/
  font-size: 14px;
  /*left: -2.5em;*/
}

.menu-item-label-active {
  display: block;
}

.field-label {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    /*line-height: 64px;*/
    color: transparent;
    -webkit-text-stroke: 1px #ff5c35;
    text-stroke: 1px #ff5c35;
    /* identical to box height */

    letter-spacing: 6px;
    text-transform: uppercase;
    /*margin: 20px 0;*/
    display: block;
}
.episodes-container {
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 1001;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.episodes-btn {
  background: url('../../assets/episodes.svg') no-repeat center/cover;
  width: 46px;
  height: 46px;
}

.episodes {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%);
  z-index: 1000;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.episode-title {
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 18px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  margin: 20px 0;
}

.episodes-thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.episode-thumbnail {
  background-size: cover;
  background-position: center;
  width: 160px;
  height: 100px;
  margin: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
}

.episode-thumbnail:hover {
  cursor: pointer;
}

.locked-episode {
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(217, 217, 217, 0.2);
  border-radius: 10px;
}

.episode-lock {
  background: url('../../assets/lock.svg') no-repeat center/cover;
  width: 77px;
  height: 77px;
}

@media only screen and (min-width: 300px) and (max-width: 1000px) {
  .episode-title {
    margin: 5px 0;
    font-size: 24px;
  }

  .episode-thumbnail {
    margin: 5px 10px;
    width: 80px;
    height: 45px;
  }

  .episode-lock {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1250px) {
  .episode-title {
    margin: 10px 0;
    font-size: 35px;
  }

  .episode-thumbnail {
    width: 100px;
    height: 60px;
  }

  .episode-lock {
    width: 46px;
    height: 46px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1500px) {
  .episode-title {
    font-size: 45px;
  }

  .episode-thumbnail {
    width: 130px;
    height: 80px;
  }

  .episode-lock {
    width: 60px;
    height: 60px;
  }
}

.add-character {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  z-index: 1001;
}

.add-character .btn-add-character {
  background: rgba(4, 4, 23, 0.66);
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 2px;
  pointer-events: all;
  padding: 40px 108px 0px 108px;
  position: absolute;
  top: 65%;
}

.btn-add-character-en {
  padding: 15px 108px 15px 108px !important;
}

.add-character .btn-add-character:hover {
  background: #ff5c35;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .add-character .btn-add-character {
    font-size: 25px;
    padding: 20px 70px 0px 70px;
    top: 65%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .add-character .btn-add-character {
    font-size: 35px;
    padding: 30px 70px 0px 70px;
    top: 70%;
  }
}

.info {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%);
  z-index: 1001;
  backdrop-filter: blur(10px);
}

.info-title-container {
  margin: 2em 100px !important;
}

.info .info-title {
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  line-height: 34px; /* 151.667% */
  letter-spacing: 0.6px;
  text-align: justify;
}

.info-partner {
  font-size: 16px !important;
  line-height: 20px !important;
}

.info-copyright {
  color: #ffd800;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.5px; /* 227.5% */
  letter-spacing: 0.4px;
}

.us-flag {
  background: url('../../../assets/us_flag.svg') no-repeat center/cover;
  width: 143px;
  height: 96px;
}

.show-animation {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  /*.info {*/
  /*    justify-content: center;*/
  /*}*/

  .info-title-container {
    margin: 2em 200px 2em 100px;
  }

  .us-flag {
    background: url('../../../assets/us_flag.svg') no-repeat center/cover;
    width: 75px;
    height: 50px;
  }

  .info-copyright {
    font-size: 12px;
  }

  .text-bold {
    font-size: 10px;
    line-height: 10px;
  }

  .info .info-title {
    font-size: 10px;
    line-height: 14px;
  }

  .info .info-cast {
    font-size: 5px;
    margin: 0;
  }

  .info-partner {
    font-size: 8px !important;
    line-height: 10px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .info {
    justify-content: center;
  }

  .info-title-container {
    margin: 2em 200px 1em 100px;
  }

  .info .info-title {
    font-size: 10px;
    line-height: 10px;
  }

  .us-flag {
    background: url('../../../assets/us_flag.svg') no-repeat center/cover;
    width: 75px;
    height: 50px;
  }

  .info .info-cast {
    font-size: 5px;
    margin: 0;
  }

  .info-copyright {
    font-size: 12px;
  }

  .info-partner {
    font-size: 8px !important;
    line-height: 10px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1500px) {
  /*.info {*/
  /*    justify-content: center;*/
  /*}*/

  .info-title-container {
    margin: 5em 250px 2em 100px;
  }

  .us-flag {
    background: url('../../../assets/us_flag.svg') no-repeat center/cover;
    width: 75px;
    height: 50px;
  }

  .info-copyright {
    font-size: 16px;
  }

  .text-bold {
    font-size: 10px;
    line-height: 10px;
  }

  .info .info-title {
    font-size: 18px;
    line-height: 24px;
  }

  .info .info-cast {
    font-size: 5px;
    margin: 0;
  }

  .info-partner {
    font-size: 12px !important;
    line-height: 10px;
  }
}

body {
  margin: 0;
  background: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  background: #000;
}

@font-face {
  font-family: 'EZO-NA';
  src:
    local('EZO-NA'),
    url(./fonts/EZO-NA-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'NasaFont';
  src:
    local('NasaFont'),
    url(./fonts/NasaFont.otf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto'),
    url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Pompiere';
  src:
    local('Pompiere'),
    url(./fonts/Pompiere-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserratarm';
  src:
    local('Montserratarm'),
    url(./fonts/Montserratarm-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'WireOne';
  src:
    local('Montserratarm'),
    url(./fonts/WireOne-Regular.ttf) format('truetype');
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes expand {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

* {
  font-family: NasaFont;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.montserrat-arm {
  font-family: Montserratarm;
}

.wire-one {
  font-family: WireOne;
}

.roboto {
  font-family: Roboto !important;
}

.watermark {
  background: url('./assets/global_to_local.svg') no-repeat center/cover;
  width: 105px;
  height: 38px;
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 1100;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .watermark {
    width: 64px;
    height: 23px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  /*.watermark {*/
  /*  width: 64px;*/
  /*  height: 23px;*/
  /*}*/
}

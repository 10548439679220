.access-denied {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 1000;
  background:
    linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url('../../assets/poster_.webp') no-repeat center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.access-denied-title {
  position: relative;
  text-transform: uppercase;
  color: #ff5c35;
  display: flex;
  width: 100%;
  justify-content: center;
}

.access-denied-title > div {
  font-size: 120px;
}

.access-denied-subtitle-container {
  padding: 0 50px;
  text-align: center;
  justify-content: center;
}

.access-denied-subtitle {
  color: #fff;
  text-align: justify;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-transform: uppercase;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .access-denied-title > div {
    font-size: 35px;
  }

  .access-denied-subtitle {
    font-size: 12px;
    line-height: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .access-denied-title > div {
    font-size: 60px;
  }

  .access-denied-subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .access-denied-title > div {
    font-size: 80px;
  }

  .access-denied-subtitle {
    font-size: 20px;
    line-height: 25px;
  }
}

.poster {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 1000;
  background:
    linear-gradient(0deg, rgba(4, 4, 23, 0.66) 0%, rgba(4, 4, 23, 0.66) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url('../../assets/poster_.webp') no-repeat center/cover;
  display: flex;
  flex-direction: column;
}

.poster-title {
  position: relative;
  height: 33%;
  text-transform: uppercase;
  color: #ff5c35;
  display: flex;
  width: 100%;
  justify-content: center;
}

.poster-title > div {
  font-size: 220px;
  position: absolute;
  bottom: -1em;
}

.poster-title-en {
  bottom: -0.5em !important;
}

.poster-start-button {
  height: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button {
  background-image: url('../../assets/play_button.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 100;
  cursor: pointer;
}

.poster-subtitle-container {
  width: 100%;
  height: 33%;
  text-align: center;
  justify-content: center;
}

.poster-subtitle {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 18px;
  text-transform: uppercase;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .poster-title > div {
    font-size: 100px;
    bottom: -0.8em;
  }

  .poster-subtitle {
    font-size: 28px;
    -webkit-text-stroke-width: 2px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .play-button {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .poster-title > div {
    font-size: 150px;
    bottom: -0.8em;
  }

  .poster-subtitle {
    font-size: 40px;
  }

  .play-button {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .poster-title > div {
    font-size: 180px;
    bottom: -0.8em;
  }

  .poster-subtitle {
    font-size: 40px;
  }
}

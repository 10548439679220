.contact-form {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  background: rgba(4, 4, 23, 0.66);
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.show-animation {
  display: flex;
  z-index: 1000;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.form {
  background: #ffffff;
  width: 434px;
}

.form-submit-btn {
  width: 434px;
}

.contact-form-form {
  margin: 50px;
}

.contact-form-form .age-form {
  display: flex;
  flex-direction: column;
}

.contact-form-form .field-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: transparent;
  -webkit-text-stroke: 1px #ff5c35;
  text-stroke: 1px #ff5c35;
  /* identical to box height */

  letter-spacing: 14px;
  text-transform: uppercase;
  margin: 20px 0;
  display: block;
}

.contact-form-form .age-form .age-range {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 118.5%;
  /* identical to box height, or 38px */

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #000000;
}

.contact-form-form .age-form .age-range-block {
  margin: 20px 0;
}

.age-range-block {
  display: flex;
  align-items: baseline;
}

.age-range-block-en {
  align-items: center !important;
}

.gender-radio {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  /*line-height: 118.5%;*/
  /* identical to box height, or 38px */

  /*letter-spacing: 0.02em;*/
  text-transform: uppercase;

  color: #000000;
}

.contact-form .btn-submit {
  border: 0;
  color: #ffffff;
  background: #ff5c35;
  font-weight: 500;
  font-size: 45px;
  line-height: 45px;
  /* identical to box height */

  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 50px 0 15px 0;
  margin-top: 40px;
  cursor: pointer;
}

.btn-submit-en {
  padding: 30px 0 30px 0 !important;
}

.language-select div {
  font-family: Roboto;
  padding: 5px;
}

button:disabled,
button[disabled] {
  opacity: 0.5 !important;
}

input[type='range'] {
  -webkit-appearance: none;
  margin: 18px 5px;
  width: 250px;
  background: #ffffff;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  background: #000000;
  border-radius: 1.3px;
  border: 0.1px solid #010101;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0.1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #000000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #000000;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  background: #000000;
  border-radius: 1.3px;
  border: 0.1px solid #010101;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0.1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #000000;
  cursor: pointer;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #000000;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  background: #000000;
  border-radius: 1.3px;
  border: 0.1px solid #010101;
}

input[type='range']::-moz-range-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0.1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #000000;
  cursor: pointer;
}

input[type='range']:focus::-moz-range-track {
  background: #000000;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 0px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  background: #000000;
  border-radius: 1.3px;
  border: 0.1px solid #010101;
}

input[type='range']::-ms-thumb {
  box-shadow:
    1px 1px 1px #000000,
    0px 0px 1px #0d0d0d;
  border: 0.1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #000000;
  cursor: pointer;
}

input[type='range']:focus::-ms-track {
  background: #000000;
}

label[for='age'] span {
  position: absolute;
  top: 0px;
  left: 10px;
  text-align: center;
  font-size: 32px;
  line-height: 60px;
  transform: translateX(-50%);
  z-index: 5;
  color: #000;
}

/*radio button styles*/
input[type='radio'] {
  border: 0px;
  width: 30px;
  height: 30px;
  accent-color: #000000;
}

@media only screen and (min-width: 300px) and (max-width: 900px) {
  .contact-form .form {
    margin-top: 15px;
    width: 330px;
  }

  .form-submit-btn {
    width: 330px;
  }

  .contact-form-form {
    margin: 10px 30px;
  }

  .contact-form-form .field-title {
    font-size: 32px;
    -webkit-text-stroke: 2px #ff5c35;
    text-stroke: 2px #ff5c35;
    margin: 0;
  }

  .contact-form-form .age-form .age-range {
    font-size: 20px;
    line-height: 40px;
  }

  input[type='radio'] {
    width: 24px;
    height: 24px;
  }

  .gender-radio {
    font-size: 24px;
  }

  input[type='range'] {
    width: 200px;
  }

  .contact-form .btn-submit {
    font-size: 30px;
    padding: 20px 0 0 0;
    margin-top: 10px;
  }

  .btn-submit-en {
    padding: 15px 0 15px 0 !important;
  }

  .contact-form-form .age-form .age-range-block {
    margin: 0;
  }

  label[for='age'] span {
    font-size: 20px;
    line-height: 38px;
  }

  .contact-form .language-select div {
    padding: 3px;
    font-size: 16px;
  }

  .contact-form .language-select {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .form {
    width: 350px;
  }

  .contact-form-form {
    margin: 30px;
  }

  .contact-form-form .field-title {
    font-size: 28px;
    margin: 10px 0;
  }

  input[type='range'] {
    width: 200px;
  }

  input[type='radio'] {
    width: 24px;
  }

  .gender-radio {
    font-size: 30px;
  }

  .form-submit-btn {
    width: 350px;
  }

  .contact-form .btn-submit {
    font-size: 28px;
    padding: 20px 0 0 0;
    margin-top: 20px;
  }

  .btn-submit-en {
    padding: 15px 0 15px 0 !important;
  }
}
